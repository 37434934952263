import React from 'react';
import { Link } from 'gatsby';

import FacebookIcon from '../assets/svgs/social/icon-facebook-md.svg';
import LinkedInIcon from '../assets/svgs/social/icon-linkedin-md.svg';
import TwitterIcon from '../assets/svgs/social/icon-twitter-md.svg';
import Partners from '../assets/svgs/partners/portugal-2020-white-xxl.svg';

const Footer = ({ cookieBanner, hideCookies }) => (
  <footer>
    <div className="flex flex-wrap flex-row  relative py-12 px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl bg-darker-gray">
      {/*First Row */}
      <div className="w-full my-4 flex flex-col justify-between">
        <div className="w-full flex flex-col sm:flex-row  justify-between ">
          {/* Addresses  */}
          <div className="w-full sm:w-1/2 sm:text-left md:text-left my-4 mx-2 text-white ">
            <h2 className="mx-2 mb-4 font-bold">Addresses</h2>
            <div className="mx-2 my-6">
              <p className="my-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                Rua Belmiro De Oliveira Nº1187 R/C, Selho São Lourenço
              </p>
              <p className="my-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                4800-134 Guimarães, Portugal
              </p>
            </div>
            <div className="mx-2 my-6">
              <p className="my-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                Rua Monte do Rio Nº165, Ponte
              </p>
              <p className="my-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                4805-289 Guimarães, Portugal
              </p>
            </div>
          </div>

          {/* Contacts */}
          <div className="w-full sm:w-1/2 sm:text-left sm:text-left my-4 mx-2 text-white">
            <h2 className="mx-2 mb-4 font-bold">Contacts</h2>
            <a href="mailto:info@lineforyou.pt" target="_blank" rel="noopener">
              <p className="my-2 mx-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                E: info@lineforyou.pt
              </p>
            </a>
            <a href="tel:+351253137648" target="_blank" rel="noopener">
              <p className="my-2 mx-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                T: +351 253 137 648
              </p>
            </a>
            <a href="tel:+351964101534" target="_blank" rel="noopener">
              <p className="my-2 mx-2 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
                M: +351 964 101 534
              </p>
            </a>
          </div>

          {/* LiNEFORYOU Pages */}
          <div className="w-full sm:w-1/2 sm:text-left sm:text-left my-4 mx-2 text-white">
            <h2 className="mx-2 mb-4 font-bold">LiNE TEXTILES</h2>
            <ul className="text-white text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2 mx-2">
              <li className="my-2">
                <Link to="/about-us/">About Us</Link>
              </li>
              <li className="my-2">
                <Link to="/contact-us/">Products</Link>
              </li>
              <li className="my-2">
                <Link to="/portugal2020/">Portugal 2020</Link>
              </li>
              <li className="my-2">
                <Link to="/covid-19/">Covid-19</Link>
              </li>
            </ul>
          </div>

          {/* Connect */}
          <div className="md:flex md:flex-col w-full sm:w-1/2 sm:text-left sm:text-left my-4 text-white">
            <ul className="flex sm:block justify-center text-white text-xs-p sm:text-sm-p md:text-md-p lg:text-lg-p">
              <h2 className="hidden mb-4 font-bold">Connect</h2>
              <li className="mx-2 md:mx-0 mb-2">
                <a
                  className="flex items-center"
                  href="https://www.linkedin.com/company/lineforyou-lda-/"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={LinkedInIcon} alt="linkedin" className="w-8" />
                  <span className="hidden md:block ml-2">LinkedIn</span>
                </a>
              </li>
              <li className="mx-2 md:mx-0 mb-2">
                <a
                  className="flex items-center"
                  href="https://www.facebook.com/LineforyouLda"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={FacebookIcon} alt="facebook" className="w-8" />
                  <span className="hidden md:block ml-2">Facebook</span>
                </a>
              </li>
              <li className="mx-2 md:mx-0 mb-2">
                <a
                  className="flex items-center"
                  href="https://twitter.com/LiNEFORYOULda"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={TwitterIcon} alt="twitter" className="w-8" />
                  <span className="hidden md:block ml-2">Twitter</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Second Row Partners*/}
      <div className="w-full container  mx-auto px-auto">
        {/* Partners*/}
        <a href="/portugal2020" className="w-full">
          <img src={Partners} className="w-full md:w-1/2 mx-auto" />
        </a>
      </div>
    </div>

    {/* Reviews and Terms of Use/Privacy Policy */}
    <div className="flex justify-center my-4  md:my-6 lg:my-8 px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl bg-white">
      <div className="mx-4 my-auto text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
        <p className="text-darker-gray uppercase">
          © {new Date().getFullYear()} LiNEFORYOU, Lda. All Rights Reserved
        </p>
      </div>
      <div className="mx-4 my-auto text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2">
        <Link
          className="mr-1 sm:mx-4 lg:mx-2 uppercase"
          to="/terms-of-use-and-privacy-policy/"
        >
          TERMS OF USE AND PRIVACY POLICY
        </Link>
        |
        <a
          className="ml-1 sm:mx-4 lg:mx-2 uppercase"
          href="https://www.livroreclamacoes.pt/inicio"
        >
          ONLINE COMPLAINT BOOK
        </a>
      </div>
    </div>

    {/* Cookies banner */}
    {cookieBanner && (
      <div className="fixed bottom-0 left-0 py-4 w-screen flex bg-darker-gray opacity-75 text-xs-p2 sm:text-sm-p2 md:text-md-p2 lg:text-lg-p2 text-white px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl ">
        <div className="w-full text-center">
          <p className="inline mr-2">
            This site uses Cookies. You can find more about this at our&nbsp;
            <Link className="underline" to="/terms-of-use-and-privacy-policy/">
              Privacy Policy.
            </Link>{' '}
            By continuing to use this site, you are agreeing to our use of
            cookies.
          </p>
          <button
            className="w-6 h-6 rounded-full bg-light-gray text-darker-gray font-bold"
            onClick={() => hideCookies()}
          >
            X
          </button>
        </div>
      </div>
    )}
  </footer>
);

export default Footer;
