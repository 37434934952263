import React from 'react';
import { Link } from 'gatsby';
import './sidebar.css';

const Sidebar = ({ isToggled, toggleSidebar, windowWidth }) => {
  return (
    <div className={windowWidth >= 480 ? 'hidden' : ''}>
      <div id="menu-icon" onClick={() => toggleSidebar()}>
        <div
          id="hamburger"
          className={'mt-8 ' + (isToggled ? 'active' : ' ')}
        ></div>
      </div>
      <div id="sidebar" className={'' + (isToggled ? 'show-sidebar' : ' ')}>
        <nav className="mt-4">
          <ul>
            <li className="my-6">
              <Link to="/" className="max-w-content sm:inline-block ">
                About Us
              </Link>
            </li>
            <li className="my-6">
              <Link to="/" className="max-w-content sm:inline-block">
                Products
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
