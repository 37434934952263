import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Transition from '../components/transition';
import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({ children, location }) => {
  const [cookieBanner, setCookieBanner] = useState(true);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const hideCookies = () => {
    setCookieBanner(false);
  };

  return (
    <Transition location={location}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="relative my-auto h-screen pt-12">
        <main>{children}</main>
        <Footer cookieBanner={cookieBanner} hideCookies={hideCookies} />
      </div>
    </Transition>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
