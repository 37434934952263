import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Sidebar from '../components/sidebar';
import './header.css';

import LineForYouLogo from '../assets/svgs/logos/logo-line-textiles-black-background.svg';

const Header = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [windowWidth]);

  const toggleSidebar = () => {
    setIsToggled(!isToggled);
  };

  return (
    <nav
      className={
        'nav flex justify-between flex-wrap px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl fixed w-full z-30 transition duration-500 ease-in-out bg-darker-gray'
      }
    >
      {/* Logo */}
      <div className="flex justify-center">
        <Link to="/" className="m-auto">
          <img
            src={LineForYouLogo}
            alt="logo"
            className="w-24 md:w-32 xl:w-160"
          />
        </Link>
      </div>
      {/* Sidebar */}
      <Sidebar
        isToggled={isToggled}
        toggleSidebar={toggleSidebar}
        windowWidth={windowWidth}
      />
      {/* Navbar options */}
      <div
        className={
          'transition duration-500 ease-in-out h-auto block flex flex-grow justify-end ' +
          ((isToggled || windowWidth < 480) && 'hidden')
        }
      >
        <div
          className={
            'flex flex-col h-full text-white sm:flex-row sm:items-center justify-around text-sm-h6 md:text-sm-h5'
          }
        >
          <div
            id="navLink"
            className="relative min-h-full mt-4 sm:mt-0 mr-4 sm:pt-6 px-4 mb-4"
          >
            <Link to="/" className="max-w-content sm:inline-block mt-auto">
              About Us
            </Link>
          </div>
          <div
            id="navLink"
            className="relative min-h-full mt-4 sm:mt-0 mr-4 sm:pt-6 px-4 mb-4"
          >
            <Link to="/" className="max-w-content sm:inline-block mt-auto">
              Products
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
